import PropTypes from 'prop-types';
import React from 'react';

import useRequest from '../../hooks/use_request';
import authentication from '../../libs/authentication';
import Suspense from '../framework/suspense';
import Initialize from './initialize';
import Signin from './signin';

const Authentication = ({ children }) => {
	return (
		<Suspense>
			<Request>{children}</Request>
		</Suspense>
	);
};

const Request = ({ children }) => {
	const { data } = useRequest(
		authentication.isSignedIn() ? 'authentication.refresh' : null,
	);

	if (!authentication.isSignedIn() || data?.status == 'error') {
		return <Signin />;
	}

	return <Initialize>{children}</Initialize>;
};

Authentication.propTypes = {
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

Request.propTypes = {
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default Authentication;
