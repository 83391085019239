import Authentication from '@zample-com/fetch/authentication';
import { navigate } from 'gatsby';

const authentication = new Authentication({
	middleware: (options) => {
		if (options?.status == 401) {
			navigate('/', { replace: true, state: { session_not_found: true } });
		} else {
			navigate('/', { replace: true });
		}
	},
});

export default authentication;
