export class RequestError extends Error {
	request = undefined;

	constructor({ message, request }) {
		super(message);

		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, RequestError);
		}

		if (request) {
			this.request = request;
		}

		this.name = 'RequestError';
		Object.setPrototypeOf(this, RequestError.prototype);
	}
}
