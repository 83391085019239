import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';

import { AdminContext } from '../../context/admin';
import useRequest from '../../hooks/use_request';
import authentication from '../../libs/authentication';
import Suspense from '../framework/suspense';

const Request = ({ children }) => {
	const { setUser } = useContext(AdminContext);
	const { data: userData } = useRequest('authenticated_user.get');

	if (userData?.status == 'error') {
		authentication.signOut();
		navigate('/', { replace: true });
	}

	useEffect(() => {
		if (userData?.status == 'ok' && userData?.data) {
			setUser(userData.data);
		}
	}, [setUser, userData]);

	return <>{children}</>;
};

const Initialize = ({ children }) => {
	return (
		<Suspense>
			<Request>{children}</Request>
		</Suspense>
	);
};

Request.propTypes = {
	children: PropTypes.any,
};

Initialize.propTypes = {
	children: PropTypes.any,
};

export default Initialize;
