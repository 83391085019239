import { Textarea as FormTextArea } from '@mantine/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

const Textarea = ({
	counter = false,
	defaultValue,
	label = true,
	max,
	name,
	placeholder,
	required,
	rows,
	value,
}) => {
	const {
		formState: { errors },
		register,
	} = useFormContext();
	const currentValue = useWatch({ name });

	const error = errors && errors[name] ? errors[name] : null;

	if (!name) return <></>;
	return (
		<FormTextArea
			autosize={rows ? false : true}
			defaultValue={defaultValue}
			description={
				counter ? (
					<>
						{currentValue?.length ?? 0} {max ? ` / ${max}` : ''}
					</>
				) : null
			}
			error={error?.message}
			label={label ? placeholder : null}
			maxLength={max}
			placeholder={placeholder}
			required={required}
			resize="vertical"
			rows={rows}
			value={value}
			{...register(name)}
		/>
	);
};

Textarea.propTypes = {
	counter: PropTypes.bool,
	defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	label: PropTypes.bool,
	max: PropTypes.number,
	name: PropTypes.string,
	placeholder: PropTypes.string,
	required: PropTypes.bool,
	rows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	value: PropTypes.string,
};

export default Textarea;
