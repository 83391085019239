import HttpClient from '@zample-com/fetch/http-client';

import authentication from './authentication';

const httpClient = new HttpClient({
	app_id: process.env.GATSBY_ZAMPLE_APP_ID,
	app_url: process.env.GATSBY_ZAMPLE_API_URL,
	authentication,
	queries: {
		ai: {
			_defaults: { base_url: '/v1/admin/ai' },
			query: { method: 'POST', url: `/query` },
		},
		analysis: {
			_defaults: { base_url: '/v1/admin/analysis' },
			approve: { method: 'POST', url: `/approve` },
			cancel: { method: 'POST', url: `/cancel` },
			create: { method: 'POST', url: `/create` },
			queue: { method: 'POST', url: `/queue` },
			similar: { url: `/similar` },
		},
		authenticated_user: {
			_defaults: { base_url: '/v1/user' },
			get: { url: `/get` },
		},
		authentication: {
			_defaults: { base_url: '/v1/authentication' },
			refresh: { method: 'POST', url: `/token/refresh` },
			signin: {
				method: 'POST',
				params: { cookie: true },
				url: `/password/signin`,
			},
			signout: { url: `/signout` },
		},
		case: {
			_defaults: { base_url: '/v1/admin/case' },
			create: { method: 'POST', url: `/create` },
			update: { method: 'POST', url: `/update` },
		},
		content_manager: {
			_defaults: { base_url: '/v1/admin/content_manager' },
			createData: { method: 'POST', url: `/create` },
			deleteData: { url: `/delete` },
			getData: { url: `/data` },
			getModels: { url: `/models` },
			list: { url: `/list` },
			updateData: { method: 'POST', url: `/update` },
		},
		cost: {
			_defaults: { base_url: '/v1/admin/cost' },
			data: { url: `/data` },
		},
		gdpr: {
			_defaults: { base_url: '/v1/admin/gdpr' },
			data: { method: 'POST', url: `/data` },
			delete: { method: 'POST', url: `/delete` },
		},
		message: {
			_defaults: { base_url: '/v1/admin/message' },
			send: { method: 'POST', url: `/send` },
		},
		order: {
			_defaults: { base_url: '/v1/admin/order' },
			cancel: { method: 'POST', url: `/cancel` },
		},
		provider: {
			_defaults: { base_url: '/v1/admin/provider' },
			execute: { method: 'POST', url: `/execute` },
		},
		statistics: {
			_defaults: { base_url: '/v1/admin/statistics' },
			analysis: { url: `/analysis` },
			model: { url: `/model` },
		},
		survey: {
			_defaults: { base_url: '/v1/admin/survey' },
			data: { url: `/data` },
		},
		user: {
			_defaults: { base_url: '/v1/admin/user' },
			change: { method: 'POST', url: `/change` },
		},
	},
});

export default httpClient;
