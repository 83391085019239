import { notifications } from '@mantine/notifications';

import { RequestError } from '../../error/request';

function useToast() {
	const handle = ({ color, description, error, title }) => {
		if (error) {
			if (error instanceof RequestError && error?.request?.message) {
				description = error.request.message;
			} else if (process.env.NODE_ENV === 'development') {
				let debug_message = '';

				if (error instanceof Error) {
					debug_message = error.toString();
				} else if (error instanceof Object && error.errors && error.errors[0]) {
					debug_message = error.errors[0].message;
				}
				if (debug_message) debug_message = ` - ${debug_message}`;

				description = `${description}${debug_message}`;
				console.log('Toast debug:', error);
			}
		}

		notifications.show({
			autoClose: 9000,
			color,
			message: description,
			title,
			withCloseButton: true,
		});
	};

	const errorToast = (props) => {
		handle({ ...props, color: 'red' });
	};
	const successToast = (props) => {
		handle({ ...props, color: 'green' });
	};

	return {
		errorToast,
		successToast,
	};
}

export default useToast;
