import {
	CheckIcon,
	CloseButton,
	Combobox,
	Group,
	Input,
	InputBase,
	Loader,
	Pill,
	useCombobox,
} from '@mantine/core';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import useSelectRequest from '../../hooks/use_select_request';

const CurrentValues = ({ activeOptions, field, handleOnChange, multiple }) => {
	if (!activeOptions?.length) return null;

	return (
		<>
			{multiple && (
				<Pill.Group>
					{activeOptions?.map((item, index) => (
						<Pill
							key={index}
							onRemove={() => handleOnChange(field, item.value)}
							withRemoveButton
						>
							{item.label}
						</Pill>
					))}
				</Pill.Group>
			)}

			{!multiple && <>{activeOptions[0].label}</>}
		</>
	);
};

const RightSection = ({ field, handleOnChange, loading, multiple }) => {
	let out = <Combobox.Chevron />;

	if (loading) {
		out = <Loader size={18} />;
	} else if (field.value && !multiple) {
		out = (
			<CloseButton
				aria-label="Clear value"
				onClick={() => {
					handleOnChange(field, null);
				}}
				onMouseDown={(event) => event.preventDefault()}
				size="sm"
			/>
		);
	}

	return out;
};

const SelectRequest = ({
	defaultValue,
	filter,
	label = true,
	model,
	multiple,
	name,
	placeholder,
	required,
}) => {
	const {
		activeOptions,
		loading,
		result,
		search,
		setActive,
		setSearch,
		setSelected,
	} = useSelectRequest({
		filter,
		id: defaultValue,
		model,
	});

	const combobox = useCombobox({
		onDropdownClose: () => {
			setSearch('');
			combobox.resetSelectedOption();
		},
		onDropdownOpen: () => {
			setActive(true);
			window.setTimeout(() => combobox.focusSearchInput(), 100);
		},
	});
	const {
		control,
		formState: { errors },
	} = useFormContext();

	const error = errors && errors[name] ? errors[name] : null;

	const handleOnChange = useCallback(
		(field, new_value) => {
			setSelected((current) => {
				if (multiple) {
					current = current.includes(new_value)
						? current.filter((item) => item !== new_value)
						: [...current, new_value];
				} else current = new_value;

				field.onChange(current);
				return current;
			});
		},
		[multiple, setSelected],
	);

	const handleSearch = (search_value) => {
		setSearch(search_value);
	};

	const groups = useMemo(() => {
		return result.map((group, index1) => {
			const options = group.items.map((item, index2) => (
				<Combobox.Option active={item.active} key={index2} value={item.value}>
					<Group gap="xs">
						{item.active ? <CheckIcon size={12} /> : null}
						<span>{item.label}</span>
					</Group>
				</Combobox.Option>
			));

			return (
				<Combobox.Group key={index1} label={group.group}>
					{options}
				</Combobox.Group>
			);
		});
	}, [result]);

	if (!name) return <></>;
	return (
		<Controller
			control={control}
			defaultValue={defaultValue}
			name={name}
			render={({ field }) => {
				return (
					<Combobox
						onOptionSubmit={(_value) => {
							handleOnChange(field, _value);
							if (!multiple) combobox.closeDropdown();
						}}
						store={combobox}
						withinPortal
					>
						<Combobox.Target>
							<InputBase
								component={multiple ? 'div' : 'button'}
								error={error?.message}
								label={label ? placeholder : null}
								multiline
								onClick={() => combobox.openDropdown()}
								onFocus={() => combobox.openDropdown()}
								placeholder={placeholder}
								pointer
								required={required}
								rightSection={
									<RightSection
										field={field}
										handleOnChange={handleOnChange}
										loading={loading}
										multiple={multiple}
									/>
								}
								rightSectionPointerEvents={
									field.value === null ? 'none' : 'all'
								}
								type="button"
							>
								{activeOptions?.length ? (
									<CurrentValues
										activeOptions={activeOptions}
										field={field}
										handleOnChange={handleOnChange}
										multiple={multiple}
									/>
								) : (
									<Input.Placeholder>Pick value</Input.Placeholder>
								)}
							</InputBase>
						</Combobox.Target>

						<Combobox.Dropdown>
							<Combobox.Search
								onChange={(event) => handleSearch(event.currentTarget.value)}
								placeholder="Search"
								value={search}
							/>
							<Combobox.Options mah={200} style={{ overflowY: 'auto' }}>
								{groups}
							</Combobox.Options>
						</Combobox.Dropdown>
					</Combobox>
				);
			}}
		/>
	);
};

CurrentValues.propTypes = {
	activeOptions: PropTypes.array,
	field: PropTypes.object,
	handleOnChange: PropTypes.func,
	multiple: PropTypes.bool,
};

RightSection.propTypes = {
	field: PropTypes.object,
	handleOnChange: PropTypes.func,
	loading: PropTypes.bool,
	multiple: PropTypes.bool,
};

SelectRequest.propTypes = {
	defaultValue: PropTypes.string,
	filter: PropTypes.object,
	label: PropTypes.bool,
	model: PropTypes.string,
	multiple: PropTypes.bool,
	name: PropTypes.string,
	placeholder: PropTypes.string,
	required: PropTypes.bool,
};

export default SelectRequest;
