import { ActionIcon, AppShell, Divider, Menu, NavLink } from '@mantine/core';
import {
	IconActivity,
	IconAt,
	IconBasket,
	IconBiohazard,
	IconCheck,
	IconCurrencyDollar,
	IconFile,
	IconGiftCard,
	IconHanger,
	IconHome,
	IconLine,
	IconMap,
	IconPlaylistAdd,
	IconTools,
	IconUser,
} from '@tabler/icons-react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import useContentSearch from '../../forms/content_search';
import useForm from '../../hooks/use_form';
import Form from '../form';
import Input from '../form/input';
import Link from '../framework/link';
import IssueHandle from '../modal/issue_handle';

const LinkItems = [
	{ icon: <IconHome />, name: 'Dashboard', url: '/' },
	{ icon: <IconActivity />, name: 'Analysis', url: 'analysis/' },
	{ icon: <IconAt />, name: 'Case', url: 'case/' },
	{ icon: <IconCurrencyDollar />, name: 'Statistics', url: 'statistics/' },
	{ icon: <IconTools />, name: 'Tools', url: 'tools/' },
	{ icon: <IconFile />, name: 'Content manager', url: 'content_manager/' },
];

const Search = ({ close }) => {
	const { handleSubmit, resolver } = useContentSearch();
	const { methods: formMethods } = useForm();
	const [model, setModel] = useState('User');
	const searchOptions = [
		{
			icon: <IconActivity size={14} />,
			model: 'Analysis',
		},
		{
			icon: <IconMap size={14} />,
			model: 'Location',
		},
		{
			icon: <IconBasket size={14} />,
			model: 'Order',
		},
		{
			icon: <IconLine size={14} />,
			model: 'Mapping',
		},
		{
			icon: <IconBiohazard size={14} />,
			model: 'Marker',
		},
		{
			icon: <IconHanger size={14} />,
			model: 'Product',
		},
		{
			icon: <IconUser size={14} />,
			model: 'User',
		},
		{
			icon: <IconGiftCard size={14} />,
			model: 'Voucher',
		},
	];
	const currentOption = searchOptions.find((item) => item.model == model);

	const onSubmit = (event) => {
		handleSubmit({ search: event.search, url: `/content_manager/${model}/` });
		close();
	};

	const ChangeModel = () => (
		<Menu>
			<Menu.Target>
				<ActionIcon variant="transparent">{currentOption.icon}</ActionIcon>
			</Menu.Target>

			<Menu.Dropdown>
				{searchOptions.map((item, index) => {
					return (
						<Menu.Item
							key={index}
							leftSection={item.icon}
							onClick={() => setModel(item.model)}
							rightSection={
								currentOption.model == item.model ? (
									<IconCheck size={10} />
								) : null
							}
						>
							{item.model}
						</Menu.Item>
					);
				})}
			</Menu.Dropdown>
		</Menu>
	);

	return (
		<Form methods={formMethods} onSubmit={onSubmit} resolver={resolver}>
			<Input
				label={false}
				leftSection={<ChangeModel />}
				name="search"
				placeholder={`Search for ${model.toLowerCase()}`}
				type="text"
			/>
		</Form>
	);
};

const Sidebar = ({ close }) => {
	return (
		<>
			<AppShell.Section>
				<Search close={close} />
			</AppShell.Section>

			<AppShell.Section mt="md">
				{LinkItems.map((link, index) => (
					<NavLink
						component={Link}
						key={index}
						label={link.name}
						leftSection={link.icon}
						onClick={close}
						to={link.url}
					/>
				))}

				<Divider my="md" />

				<IssueHandle
					button={({ open }) => (
						<NavLink
							label="Create Issue"
							leftSection={<IconPlaylistAdd />}
							onClick={open}
						/>
					)}
				/>
			</AppShell.Section>
		</>
	);
};

Search.propTypes = {
	close: PropTypes.func,
};

Sidebar.propTypes = {
	close: PropTypes.func,
};

export default Sidebar;
