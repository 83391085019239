import {
	AppShell,
	Box,
	Card,
	CopyButton,
	Divider,
	ScrollArea,
	Stack,
	Title,
	Tooltip,
} from '@mantine/core';
import { IconAi } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

import { AdminContext } from '../../context/admin';
import { RequestError } from '../../error/request';
import useForm from '../../hooks/use_form';
import useMutate from '../../hooks/use_mutate';
import useRequest from '../../hooks/use_request';
import AiPrompts from '../ai/prompts';
import AsideHeader from '../aside/header';
import Form from '../form';
import Select from '../form/select';
import Submit from '../form/submit';
import Suspense from '../framework/suspense';
import useToast from '../framework/toast';

const AnalysisQueryAiAside = ({ data }) => {
	const { methods: formMethods } = useForm();
	const [result, setResult] = useState();

	return (
		<>
			<AsideHeader icon={<IconAi />} title="Query AI" />

			<AppShell.Section component={ScrollArea} grow my="md">
				{result?.prompts?.length > 0 && (
					<Card mb="md" padding="lg" radius="md" shadow="sm" withBorder>
						<AiPrompts
							items={result?.prompts}
							settings={result?.settings}
							template_id={formMethods?.getValues('template_id')}
							variant="white"
						/>
					</Card>
				)}

				{result?.messages?.length > 0 && (
					<Card mb="md" padding="lg" radius="md" shadow="sm" withBorder>
						<Title mb="md" size="sm">
							Messages
						</Title>

						<Stack>
							{data.messages.map((value, index) => {
								return (
									<Box key={index}>
										<Divider
											fw={700}
											label={
												<CopyButton value={value}>
													{({ copied, copy }) => (
														<Tooltip
															label={copied ? 'Copied' : 'Copy'}
															position="right"
															withArrow
														>
															<Box onClick={copy}>Result: {index + 1}</Box>
														</Tooltip>
													)}
												</CopyButton>
											}
											labelPosition="left"
											my="xs"
											tt="uppercase"
										/>

										<Box
											className="QueryAi"
											size="sm"
											style={{ whiteSpace: 'pre-line' }}
										>
											{value}
										</Box>
									</Box>
								);
							})}
						</Stack>
					</Card>
				)}
			</AppShell.Section>
			<AppShell.Section>
				<Suspense>
					<Request
						data={data}
						formMethods={formMethods}
						setResult={setResult}
					/>
				</Suspense>
			</AppShell.Section>
		</>
	);
};

const Request = ({ data, formMethods, setResult }) => {
	const {
		constants: { languages },
		language,
	} = useContext(AdminContext);
	const { trigger } = useMutate('ai.query');
	const { errorToast } = useToast();

	const { data: analysisResponse } = useRequest(
		data?.analysis_id ? 'content_manager.getData' : null,
		{
			params: {
				fields: ['*'],
				id: data?.analysis_id,
				model: 'Analysis',
			},
		},
	);
	const analysis = analysisResponse?.data;

	const { data: templateResponse } = useRequest(
		analysis?.partner_id ? 'content_manager.list' : null,
		{
			params: {
				fields: ['id', 'name', 'partner.*'],
				filter: {
					destination: { $eq: 'ai' },
					partner_id: { $eq: analysis?.partner_id },
				},
				limit: 1000,
				model: 'Template',
			},
		},
	);
	const templates = templateResponse?.data;

	const onSubmit = async (values) => {
		try {
			const result = await trigger({
				analysis_id: data?.analysis_id,
				language: values.language,
				save: values.action == 'run_save' ? true : false,
				template_id: values.template_id,
				test: values.action == 'test' ? true : false,
			});
			if (result?.status == 'error') {
				throw new RequestError({ request: result });
			}

			setResult(result.data);
		} catch (error) {
			let description = 'Unable to query the AI, please try again.';
			const title = 'Error';

			errorToast({
				description,
				error,
				title,
			});
		}
	};

	return (
		<Form methods={formMethods} onSubmit={onSubmit}>
			<Stack gap="xs">
				<Select
					data={[
						{
							label: 'Query AI',
							value: 'run',
						},
						{
							label: 'Query AI + save',
							value: 'run_save',
						},
						{
							label: 'Test, get prompts only',
							value: 'test',
						},
					]}
					defaultValue="test"
					name="action"
					placeholder="Action"
				/>
				<Select
					data={languages?.map((item) => ({
						label: item.name,
						value: item.id + '',
					}))}
					defaultValue={language?.id + ''}
					name="language"
					placeholder="Language"
				/>
				<Select
					data={templates?.items?.map((item) => ({
						label: item.name,
						value: `${item.id}`,
					}))}
					name="template_id"
					placeholder="Template id"
				/>
				<Submit name="Get" />
			</Stack>
		</Form>
	);
};

AnalysisQueryAiAside.propTypes = {
	data: PropTypes.object,
};

Request.propTypes = {
	data: PropTypes.object,
	formMethods: PropTypes.object,
	setResult: PropTypes.func,
};

export default AnalysisQueryAiAside;
