import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import useToast from '../components/framework/toast';
import { RequestError } from '../error/request';
import useMutate from '../hooks/use_mutate';

const validationSchema = Yup.object({
	password: Yup.string().defined().required(),
	username: Yup.string().email().defined().required(),
});

function useSigninForm() {
	const { trigger } = useMutate('authentication.signin');

	const { errorToast } = useToast();
	const initialValues = {
		password: '',
		username: '',
	};

	const handleSubmit = async (data) => {
		try {
			const result = await trigger({
				password: data.password,
				username: data.username,
			});
			if (result?.status == 'error') {
				throw new RequestError({ request: result });
			}

			return result;
		} catch (error) {
			errorToast({
				description: 'Please check username and/or password.',
				error,
				title: 'Unable to sign in',
			});
		}
	};
	const resolver = yupResolver(validationSchema);

	return {
		handleSubmit,
		initialValues,
		resolver,
	};
}

export default useSigninForm;
