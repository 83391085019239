import { yupResolver } from '@hookform/resolvers/yup';
import { navigate } from 'gatsby';
import * as Yup from 'yup';

const validationSchema = Yup.object({});

function useContentSearch() {
	const initialValues = {
		search: '',
	};

	const handleSubmit = async (data) => {
		navigate(`${data.url}?search=${data.search}`, {
			replace: true,
		});
	};
	const resolver = yupResolver(validationSchema);

	return {
		handleSubmit,
		initialValues,
		resolver,
	};
}

export default useContentSearch;
