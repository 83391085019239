import { Alert } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import React from 'react';

import Seo from '../framework/seo';

const NotFound = ({ uri }) => (
	<>
		<Seo title="404 - Page not found" />
		<Alert icon={<IconAlertCircle />} title="404 - Page not found">
			{uri}
		</Alert>
	</>
);

NotFound.propTypes = {
	uri: PropTypes.string,
};

export default NotFound;
