import { Box, Loader, Text } from '@mantine/core';
import React from 'react';

const Loading = () => (
	<Box>
		<Text size="xl">Loading, please wait...</Text>
		<Loader color="blue" />
	</Box>
);

export default Loading;
