import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { FormProvider } from 'react-hook-form';

const Form = forwardRef(function Form(
	{ children, hasForm = true, methods, onSubmit },
	ref,
) {
	return (
		<FormProvider {...methods}>
			{hasForm && (
				<form onSubmit={methods.handleSubmit(onSubmit)} ref={ref}>
					{children}
				</form>
			)}
			{!hasForm && <>{children}</>}
		</FormProvider>
	);
});

Form.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.object,
		PropTypes.func,
	]).isRequired,
	hasForm: PropTypes.bool,
	methods: PropTypes.object.isRequired,
	onSubmit: PropTypes.func,
};

export default Form;
