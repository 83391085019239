import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';

const Seo = ({ title }) => (
	<Helmet titleTemplate={`%s | Zample`}>
		<title>{title}</title>
	</Helmet>
);

Seo.propTypes = {
	title: PropTypes.string
};

export default Seo;
