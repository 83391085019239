import React, { useContext } from 'react';

import { AdminContext } from '../../context/admin';
import AiAside from '../ai/aside';
import AnalysisQueryAiAside from '../analysis/query_ai_aside';
import AnalysisSimilarAside from '../analysis/similar_aside';
import DatasetAside from '../dataset/aside';

const Aside = () => {
	const { aSideState } = useContext(AdminContext);

	return (
		<>
			{aSideState?.component == 'ai' && <AiAside data={aSideState?.data} />}
			{aSideState?.component == 'analysis_similar' && (
				<AnalysisSimilarAside data={aSideState?.data} />
			)}
			{aSideState?.component == 'analysis_query_ai' && (
				<AnalysisQueryAiAside data={aSideState?.data} />
			)}
			{aSideState?.component == 'dataset' && (
				<DatasetAside data={aSideState?.data} />
			)}
		</>
	);
};

export default Aside;
