import {
	Alert,
	Anchor,
	AppShell,
	Box,
	ScrollArea,
	Stack,
	Table,
	Tooltip,
} from '@mantine/core';
import { IconGitCompare } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import React from 'react';

import useRequest from '../../hooks/use_request';
import AsideHeader from '../aside/header';
import Badge from '../content_manager/badge';
import Link from '../framework/link';
import Suspense from '../framework/suspense';

const AnalysisSimilarAside = ({ data }) => {
	return (
		<>
			<AsideHeader icon={<IconGitCompare />} title="Similar analysis" />

			<AppShell.Section component={ScrollArea} grow my="md">
				<Suspense>
					<Request data={data} />
				</Suspense>
			</AppShell.Section>
		</>
	);
};

const Request = ({ data }) => {
	const similarAnalysis = {
		product: {
			color: 'yellow',
			data: null,
			title: 'Product matches',
		},
		similar: {
			color: 'orange',
			data: null,
			title: 'Product + marker + reference state matches',
		},
		similar_case: {
			color: 'green',
			data: null,
			title: 'Product + marker + reference state matches and has a case',
		},
	};
	const { data: similarResponse } = useRequest(
		data?.analysis_id ? 'analysis.similar' : null,
		{
			params: {
				analysis_id: data?.analysis_id,
			},
		},
	);
	const similarData = similarResponse?.data;

	const { data: tmpSimilarAnalysisData1 } = useRequest(
		similarData?.product_analysis_ids?.length ? 'content_manager.list' : null,
		{
			params: {
				fields: ['*'],
				filter: { id: { $in: similarData?.product_analysis_ids } },
				limit: 1000,
				model: 'Analysis',
			},
		},
	);
	similarAnalysis.product.data = tmpSimilarAnalysisData1?.data?.items;

	const { data: tmpSimilarAnalysisData2 } = useRequest(
		similarData?.similar_analysis_ids?.length ? 'content_manager.list' : null,
		{
			params: {
				fields: ['*'],
				filter: { id: { $in: similarData?.similar_analysis_ids } },
				limit: 1000,
				model: 'Analysis',
			},
		},
	);
	similarAnalysis.similar.data = tmpSimilarAnalysisData2?.data?.items;

	const { data: tmpSimilarAnalysisData3 } = useRequest(
		similarData?.similar_analysis_ids?.length ? 'content_manager.list' : null,
		{
			params: {
				fields: ['*'],
				filter: { id: { $in: similarData?.similar_with_case_analysis_ids } },
				limit: 1000,
				model: 'Analysis',
			},
		},
	);
	similarAnalysis.similar_case.data = tmpSimilarAnalysisData3?.data?.items;

	return (
		<Stack>
			{Object.values(similarAnalysis)
				.reverse()
				.map((item, index1) => {
					return (
						<Alert color={item.color} key={index1} title={item.title}>
							{!item?.data && <>No matches</>}

							{item?.data && (
								<Table.ScrollContainer>
									<Table highlightOnHover>
										<Table.Thead>
											<Table.Tr>
												<Table.Th>Id</Table.Th>
												<Table.Th>Partner</Table.Th>
												<Table.Th>Status</Table.Th>
												<Table.Th>Laboratory</Table.Th>
											</Table.Tr>
										</Table.Thead>
										<Table.Tbody>
											{item.data.map((value, index2) => {
												return (
													<Table.Tr key={index2}>
														<Table.Td>
															<Anchor
																component={Link}
																to={`/analysis/view/${value.id}/`}
															>
																{value.id}
															</Anchor>
														</Table.Td>
														<Table.Td>
															<Badge
																id={Number(value?.partner?.id)}
																size="lg"
																variant="filled"
															>
																<Tooltip label={value?.partner?.name}>
																	<Box>
																		{value?.partner?.symbol ||
																			value?.partner?.name ||
																			null}
																	</Box>
																</Tooltip>
															</Badge>
														</Table.Td>
														<Table.Td>
															<Badge id={value.status}>{value.status}</Badge>
														</Table.Td>
														<Table.Td>{value.laboratory?.name}</Table.Td>
													</Table.Tr>
												);
											})}
										</Table.Tbody>
									</Table>
								</Table.ScrollContainer>
							)}
						</Alert>
					);
				})}
		</Stack>
	);
};

AnalysisSimilarAside.propTypes = {
	data: PropTypes.object,
};

Request.propTypes = {
	data: PropTypes.object,
};

export default AnalysisSimilarAside;
