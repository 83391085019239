import { Badge } from '@mantine/core';
import { IconTag } from '@tabler/icons-react';
import { NodeViewWrapper } from '@tiptap/react';
import PropTypes from 'prop-types';
import React from 'react';

const Tag = ({ node, selected }) => {
	const attrs = node?.attrs;
	let title = '';

	if (attrs.title) {
		title = attrs.title;
	}

	for (const key in attrs) {
		if (key == 'title' || !attrs[key]) continue;
		if (title) title += ', ';
		title += `${key}=${attrs[key]}`;
	}

	return (
		<NodeViewWrapper>
			<Badge
				leftSection={<IconTag />}
				variant={selected ? 'filled' : 'outline'}
			>
				{title}
			</Badge>
		</NodeViewWrapper>
	);
};

Tag.propTypes = {
	node: PropTypes.object,
	selected: PropTypes.bool,
};

export default Tag;
