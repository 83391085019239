import { Alert, Button, Code, Collapse, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconInfoCircle } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import React, { Suspense as ReactSuspense, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import Loading from './loading';

function FallbackError({ error }) {
	const [opened, { toggle }] = useDisclosure(false);

	return (
		<Alert color="red" icon={<IconInfoCircle />} title="Error" variant="light">
			<Text mb="sm">
				Something went wrong, reload the webpage and try again.
			</Text>
			<Button onClick={toggle} size="xs" variant="light">
				{opened ? 'Hide details' : 'Show details'}
			</Button>
			<Collapse in={opened}>
				<Code block mt="sm">
					{error.message}
				</Code>
			</Collapse>
		</Alert>
	);
}

const Suspense = ({ children, fallback }) => {
	const [show, setShow] = useState(false);

	useEffect(() => {
		setShow(true);
	}, []);

	if (!fallback) fallback = <Loading />;
	return (
		<>
			{show ? (
				<ErrorBoundary FallbackComponent={FallbackError}>
					<ReactSuspense fallback={fallback}>{children}</ReactSuspense>
				</ErrorBoundary>
			) : null}
		</>
	);
};

FallbackError.propTypes = {
	error: PropTypes.object,
};

Suspense.propTypes = {
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	fallback: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default Suspense;
