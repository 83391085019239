import {
	MultiSelect as MantineMultiSelect,
	Select as MantineSelect,
} from '@mantine/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const Select = ({
	clearable = true,
	data,
	defaultValue,
	label = true,
	multiple = false,
	name,
	onChange,
	onSearchChange,
	placeholder,
	required,
	searchValue,
	value,
	...props
}) => {
	const {
		control,
		formState: { errors },
	} = useFormContext();

	const error = errors && errors[name] ? errors[name] : null;
	const SelectType = multiple ? MantineMultiSelect : MantineSelect;

	const optionsFilter = ({ limit, options, search }) => {
		const parsedSearch = search.trim().toLowerCase();
		const result = [];

		for (let i = 0; i < options.length; i += 1) {
			const item = options[i];
			if (result.length === limit) return result;

			if ('group' in item && item?.group == 'current') {
				result.push({
					group: item.group,
					items: item.items,
				});
			} else if ('group' in item) {
				result.push({
					group: item.group,
					items: optionsFilter({
						limit: limit - result.length,
						options: item.items,
						search,
					}),
				});
			} else {
				if (item.label.toLowerCase().includes(parsedSearch)) {
					result.push(item);
				}
			}
		}

		return result;
	};

	if (!name) return <></>;
	return (
		<Controller
			control={control}
			defaultValue={defaultValue}
			name={name}
			render={({ field }) => {
				return (
					<SelectType
						{...props}
						{...field}
						clearable={clearable}
						data={data}
						defaultValue={defaultValue}
						error={error?.message}
						filter={optionsFilter}
						label={label ? placeholder : null}
						nothingFoundMessage="Nothing found"
						onChange={(_value) => {
							if (onChange) onChange(_value);
							field.onChange(_value);
						}}
						onSearchChange={onSearchChange}
						placeholder={placeholder}
						required={required}
						searchValue={searchValue}
						searchable
						value={value}
					/>
				);
			}}
		/>
	);
};

Select.propTypes = {
	clearable: PropTypes.bool,
	data: PropTypes.array,
	defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	label: PropTypes.bool,
	multiple: PropTypes.bool,
	name: PropTypes.string,
	onChange: PropTypes.func,
	onSearchChange: PropTypes.func,
	placeholder: PropTypes.string,
	required: PropTypes.bool,
	searchValue: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Select;
