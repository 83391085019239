import { Menu } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { AdminContext } from '../../context/admin';

const Language = ({ children }) => {
	const {
		constants: { languages },
		language,
		setSetting,
	} = useContext(AdminContext);

	return (
		<Menu shadow="md">
			<Menu.Target>{children}</Menu.Target>

			<Menu.Dropdown>
				<Menu.Label>Language</Menu.Label>

				{languages?.map((item, index) => {
					return (
						<Menu.Item
							disabled={item.id == language?.id}
							key={index}
							onClick={() => setSetting('language_id', item.id)}
							rightSection={
								item.id == language?.id ? <IconCheck size={10} /> : null
							}
						>
							{item.name}
						</Menu.Item>
					);
				})}
			</Menu.Dropdown>
		</Menu>
	);
};

Language.propTypes = {
	children: PropTypes.node,
};

export default Language;
