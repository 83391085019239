import { useEffect } from 'react';
import { useForm as useFormReactHookForm } from 'react-hook-form';

function useForm({ defaultValues, onChange, resolver } = {}) {
	const methods = useFormReactHookForm({
		defaultValues,
		mode: 'onBlur',
		resolver,
	});

	useEffect(() => {
		const subscription = methods.watch((value, { name, type }) => {
			if (onChange) {
				onChange({ name, type, value });
			}
		});
		return () => subscription.unsubscribe();
	}, [methods, onChange]);

	return { methods };
}

export default useForm;
