import { Mark, mergeAttributes } from '@tiptap/core';

export default Mark.create({
	addCommands() {
		return {
			setSmall:
				() =>
				({ commands }) => {
					return commands.setMark(this.name);
				},
			toggleSmall:
				() =>
				({ commands }) => {
					return commands.toggleMark(this.name);
				},
			unsetSmall:
				() =>
				({ commands }) => {
					return commands.unsetMark(this.name);
				},
		};
	},
	addOptions() {
		return {
			HTMLAttributes: {},
		};
	},

	name: 'small',

	parseHTML() {
		return [{ tag: 'small' }];
	},

	renderHTML({ HTMLAttributes }) {
		return [
			'small',
			mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
			0,
		];
	},

	selectable: true,
});
