import { Link as ReachLink } from '@reach/router';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

const Link = forwardRef(function Link({ children, to, ...props }, ref) {
	return (
		<ReachLink ref={ref} to={to} {...props}>
			{children}
		</ReachLink>
	);
});

Link.propTypes = {
	children: PropTypes.node,
	to: PropTypes.string,
};

export default Link;
