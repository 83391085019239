'use strict';

import { useDebouncedValue } from '@mantine/hooks';
import { useEffect, useMemo, useState } from 'react';

import useRequest from './use_request';

function useSelectRequest({ filter, id, model }) {
	const [active, setActive] = useState(false);
	const [loading, setLoading] = useState(false);
	const [search, setSearch] = useState('');
	const [selected, setSelected] = useState([]);
	const [debouncedSearch] = useDebouncedValue(search, 500);

	const { data: contentData, isLoading: isLoadingContentData } = useRequest(
		id ? 'content_manager.getData' : null,
		{
			params: {
				id,
				model,
			},
			suspense: false,
		},
	);
	const { data: selectedContentData, isLoading: isLoadingSelectedContentData } =
		useRequest(selected?.length ? 'content_manager.list' : null, {
			params: {
				filter: { ...filter, id: { $in: selected } },
				limit: 100,
				model,
			},
			suspense: false,
		});
	const { data: searchContentData, isLoading: isLoadingSearchContentData } =
		useRequest(active ? 'content_manager.list' : null, {
			params: {
				filter: { ...filter, id: { $nin: selected } },
				model,
				search: debouncedSearch,
			},
			suspense: false,
		});

	useEffect(() => {
		setLoading(
			isLoadingContentData ||
				isLoadingSearchContentData ||
				isLoadingSelectedContentData,
		);
	}, [
		isLoadingContentData,
		isLoadingSearchContentData,
		isLoadingSelectedContentData,
	]);

	const result = useMemo(() => {
		let items = {};
		let out = [];

		items.current = contentData?.data ? [contentData?.data] : [];
		items.selected = selectedContentData?.data?.items?.length
			? selectedContentData.data.items
			: [];
		items.options = searchContentData?.data?.items?.length
			? searchContentData.data.items
			: [];

		for (const group in items) {
			let tmp = { group, items: [] };

			for (const item of items[group]) {
				if (
					group != 'current' &&
					items?.current?.length &&
					items?.current?.find((check) => check.id == item.id)
				) {
					continue;
				}

				let check_active = false;
				let description = '';
				let name = '';
				let symbol = '';

				if (
					group == 'current' &&
					((items.current?.length && !items.selected?.length) ||
						items.selected?.find((check) => check.id == id))
				) {
					check_active = true;
				} else if (group == 'selected' && items.selected?.length) {
					check_active = true;
				}

				if (item.first_name) {
					name = `${item.first_name} ${item.last_name || null} (${
						item.username
					})`;
				} else {
					name = item.name || item.username || item.title || 'No name';
				}

				if (item.description) {
					description = ` - ${item.description}`;
				}
				if (item.symbol) {
					symbol = ` - (${item.symbol})`;
				}

				tmp.items.push({
					active: check_active,
					label: `${item.id}: ${name}${description}${symbol}`,
					value: `${item.id}`,
				});
			}

			out.push(tmp);
		}

		return out;
	}, [contentData, id, searchContentData, selectedContentData]);

	const activeOptions = useMemo(() => {
		if (!result) return null;

		let out = [];

		for (const group of result) {
			for (const item of group.items) {
				if (!item.active) continue;
				out.push(item);
			}
		}

		return out;
	}, [result]);

	return {
		activeOptions,
		loading,
		result,
		search,
		selected,
		setActive,
		setSearch,
		setSelected,
	};
}

export default useSelectRequest;
