import { Center, Flex, Paper, Stack } from '@mantine/core';
import { navigate } from 'gatsby';
import React from 'react';

import useSigninForm from '../../forms/signin';
import useForm from '../../hooks/use_form';
import authentication from '../../libs/authentication';
import Form from '../form';
import Input from '../form/input';
import Submit from '../form/submit';

const Signin = () => {
	const { methods: formMethods } = useForm();
	const { handleSubmit, resolver } = useSigninForm();

	const onSubmit = async (event) => {
		const result = await handleSubmit(event);

		if (result && result.status == 'ok') {
			authentication.signIn(result.data.token);
			navigate('/', { replace: true });
		}
	};

	return (
		<Flex align="center" justify="center" mt="xl">
			<Center maw={500}>
				<Paper>
					<Form methods={formMethods} onSubmit={onSubmit} resolver={resolver}>
						<Stack gap="md">
							<Input name="username" placeholder="Email address" type="email" />
							<Input name="password" placeholder="Password" type="password" />
							<Stack gap="md">
								<Submit name="Sign in" />
							</Stack>
						</Stack>
					</Form>
				</Paper>
			</Center>
		</Flex>
	);
};

export default Signin;
