import { Node, mergeAttributes } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import Tag from '../../components/extensions/tiptap/tag';

export default Node.create({
	addAttributes() {
		return {
			allergen_id: {
				default: null,
			},
			article_id: {
				default: null,
			},
			marker_id: {
				default: null,
			},
			product_id: {
				default: null,
			},
			title: {
				default: null,
			},
		};
	},
	addCommands() {
		return {
			setTag:
				(options) =>
				({ commands }) => {
					return commands.insertContent({
						attrs: options,
						type: this.name,
					});
				},
		};
	},
	addNodeView() {
		return ReactNodeViewRenderer(Tag);
	},
	addOptions() {
		return {
			HTMLAttributes: {},
		};
	},
	atom: true,

	group: 'inline',

	inline: true,

	name: 'tag',

	parseHTML() {
		return [{ tag: `span[data-type="${this.name}"]` }, { tag: `Tag` }];
	},

	renderHTML({ HTMLAttributes }) {
		return [
			'span',
			mergeAttributes(
				{ 'data-type': this.name },
				this.options.HTMLAttributes,
				HTMLAttributes
			),
		];
	},

	selectable: true,
});
