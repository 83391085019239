import { Spotlight as MantineSpotlight } from '@mantine/spotlight';
import { navigate } from '@reach/router';
import {
	IconActivity,
	IconAt,
	IconCurrencyDollar,
	IconFile,
	IconHome,
	IconPlus,
	IconSearch,
	IconTools,
} from '@tabler/icons-react';
import React, { useMemo } from 'react';

import useRequest from '../../hooks/use_request';

const Spotlight = () => {
	const { data } = useRequest('content_manager.getModels', {
		params: {
			models: ['*'],
		},
	});

	const actions = useMemo(() => {
		let out = [
			{
				actions: [
					{
						id: 'page_dashboard',
						label: 'Dashboard',
						leftSection: <IconHome />,
						onClick: () => navigate('/'),
					},
					{
						id: 'page_case',
						label: 'Case',
						leftSection: <IconAt />,
						onClick: () => navigate('/case/'),
					},
					{
						id: 'page_content_manager',
						label: 'Content manager',
						leftSection: <IconFile />,
						onClick: () => navigate('/content_manager/'),
					},
					{
						id: 'page_tools',
						label: 'Tools',
						leftSection: <IconTools />,
						onClick: () => navigate('/tools/'),
					},
				],
				group: 'Pages',
			},
			{
				actions: [
					{
						id: 'page_analysis',
						label: 'Analysis',
						leftSection: <IconActivity />,
						onClick: () => navigate('/analysis/'),
					},
				],
				group: 'Analysis',
			},
			{
				actions: [
					{
						id: 'page_statistics_financial',
						label: 'Financial',
						leftSection: <IconCurrencyDollar />,
						onClick: () => navigate('/statistics/financial/'),
					},
					{
						id: 'page_statistics_product_cost',
						label: 'Product cost',
						leftSection: <IconCurrencyDollar />,
						onClick: () => navigate('/statistics/product_cost/'),
					},
					{
						id: 'page_statistics',
						label: 'Statistics',
						leftSection: <IconCurrencyDollar />,
						onClick: () => navigate('/statistics/'),
					},
				],
				group: 'Statistics',
			},
		];

		if (data?.data) {
			let content_manager_actions = [];

			for (const key of Object.keys(data.data)) {
				const description = data.data[key]?.information?.description ?? '';
				const label =
					data.data[key]?.information?.name ?? key.split(/(?=[A-Z])/).join(' ');

				content_manager_actions.push({
					description,
					id: `page_content_manager_${key.toLowerCase()}`,
					label,
					leftSection: <IconFile />,
					onClick: () => navigate(`/content_manager/${key}`),
				});
			}

			out.push({
				actions: content_manager_actions,
				group: 'Content manager',
			});
		}

		return out;
	}, [data]);

	return (
		<MantineSpotlight
			actions={actions}
			highlightQuery
			nothingFound="Nothing found!"
			scrollable
			searchProps={{
				leftSection: <IconSearch />,
				placeholder: 'Search',
			}}
		/>
	);
};

export default Spotlight;
