import { useSet } from '@mantine/hooks';
import PropTypes from 'prop-types';
import React, { createContext, useCallback, useMemo, useState } from 'react';

const default_values = {
	addItem: () => {},
	hasOptions: false,
	items: [],
	options: {},
	reset: () => {},
	toggleOption: () => {},
};

export const AiContext = createContext(default_values);

export const AiProvider = ({ children }) => {
	const items = useSet([]);
	const [options, setOptions] = useState({});

	const addItem = useCallback(
		({ content, input, prompts, settings, template_id }) => {
			if (!content) return false;
			items.add({
				content,
				date: new Date().toISOString(),
				input,
				prompts,
				settings,
				template_id,
			});
			return true;
		},
		[items],
	);

	const hasOptions = useMemo(() => {
		return Boolean(Object.keys(options)?.length);
	}, [options]);

	const reset = useCallback(() => {
		items.clear();
		setOptions({});
	}, [items, setOptions]);

	const toggleOption = useCallback(
		(key, value) => {
			if (!key) return;

			setOptions((current) => {
				const state = { ...current };
				if (state[key]) delete state[key];
				else state[key] = value;
				return state;
			});
		},
		[setOptions],
	);

	const values = {
		addItem,
		hasOptions,
		items,
		options,
		reset,
		toggleOption,
	};
	return <AiContext.Provider value={values}>{children}</AiContext.Provider>;
};

AiProvider.propTypes = {
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
