import { Button } from '@mantine/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useFormState } from 'react-hook-form';

const Submit = ({
	loading = false,
	name,
	onClick,
	type = 'submit',
	useIsDirty = true,
	...props
}) => {
	let { isDirty, isSubmitting, isValid, isValidating } = useFormState();

	let disabled = !isDirty || isSubmitting || !isValid || isValidating;
	if (!useIsDirty) {
		disabled = isSubmitting || !isValid || isValidating;
	}

	return (
		<Button
			disabled={disabled}
			loading={loading || isSubmitting}
			onClick={onClick}
			type={type}
			{...props}
		>
			{name}
		</Button>
	);
};

Submit.propTypes = {
	loading: PropTypes.bool,
	name: PropTypes.string,
	onClick: PropTypes.func,
	type: PropTypes.string,
	useIsDirty: PropTypes.bool,
};

export default Submit;
