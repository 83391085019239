import { AppShell, Avatar, CloseButton, Group, Menu } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { AdminContext } from '../../context/admin';

const AsideHeader = ({ icon, title }) => {
	const {
		aSideClose,
		constants: { aside_sizes },
		setSetting,
		settings,
	} = useContext(AdminContext);

	const handleSizeChange = (size) => {
		setSetting('aside_size', size);
	};

	return (
		<AppShell.Section>
			<Group justify="space-between">
				<Menu position="bottom-start" withArrow withinPortal>
					<Menu.Target>
						<Avatar name={title}>{icon}</Avatar>
					</Menu.Target>

					<Menu.Dropdown>
						<Menu.Label>Size</Menu.Label>
						{Object.keys(aside_sizes).map((key, index) => {
							return (
								<Menu.Item
									key={index}
									onClick={() => handleSizeChange(key)}
									rightSection={
										settings?.aside_size == key ? <IconCheck size={10} /> : null
									}
									tt="capitalize"
								>
									{key}
								</Menu.Item>
							);
						})}
					</Menu.Dropdown>
				</Menu>

				<CloseButton onClick={aSideClose} />
			</Group>
		</AppShell.Section>
	);
};

AsideHeader.propTypes = {
	icon: PropTypes.node,
	title: PropTypes.string,
};

export default AsideHeader;
