import {
	ActionIcon,
	Anchor,
	Button,
	Card,
	Collapse,
	CopyButton,
	Group,
	Stack,
	Text,
	TypographyStylesProvider,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconChevronDown, IconChevronUp, IconCopy } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import React from 'react';
import ReactMarkdown from 'react-markdown';

import { formatDateTime } from '../../services/utils/helpers';
import AiPrompts from '../ai/prompts';
import Link from '../framework/link';

const Item = ({ item }) => {
	const [opened, { toggle }] = useDisclosure(false);

	return (
		<Card mb="md" padding="lg" radius="md" shadow="sm" withBorder>
			<Card.Section inheritPadding mb="sm" py="xs" withBorder>
				<Group justify="space-between">
					<Stack gap={0}>
						{item?.dataset?.id && item?.dataset?.name && (
							<Anchor
								component={Link}
								size="xs"
								to={`/content_manager/Dataset/${item.dataset.id}/`}
							>
								{item.dataset.name}
							</Anchor>
						)}
						<Text size="xs">{formatDateTime(item.created_at)}</Text>
					</Stack>
					{item?.prompts?.length && (
						<ActionIcon
							color="gray"
							onClick={toggle}
							size="xs"
							variant="transparent"
						>
							{opened ? <IconChevronUp /> : <IconChevronDown />}
						</ActionIcon>
					)}
				</Group>

				{item?.prompts?.length && (
					<Collapse in={opened} mt="xs">
						<AiPrompts items={item?.prompts} template_id={item.template_id} />
					</Collapse>
				)}
			</Card.Section>

			<Card.Section inheritPadding mb="sm" pb="sm" withBorder>
				<TypographyStylesProvider>
					<ReactMarkdown>{item.content}</ReactMarkdown>
				</TypographyStylesProvider>
			</Card.Section>

			<CopyButton value={item.content}>
				{({ copied, copy }) => (
					<Button
						color={copied ? 'green' : ''}
						leftSection={<IconCopy />}
						onClick={copy}
						size="xs"
						variant="light"
					>
						{copied ? 'Copied' : 'Copy'}
					</Button>
				)}
			</CopyButton>
		</Card>
	);
};

Item.propTypes = {
	item: PropTypes.object,
};

export default Item;
