import { AppShell, ScrollArea } from '@mantine/core';
import { IconDatabase } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import React from 'react';

import useRequest from '../../hooks/use_request';
import AsideHeader from '../aside/header';
import Suspense from '../framework/suspense';
import DatasetItem from './item';

const DatasetAside = ({ data }) => {
	return (
		<>
			<AsideHeader icon={<IconDatabase />} title="Dataset" />

			<AppShell.Section component={ScrollArea} grow my="md">
				<Suspense>
					<Request data={data} />
				</Suspense>
			</AppShell.Section>
		</>
	);
};

const Request = ({ data }) => {
	const { data: analysisDatasetItemResponse } = useRequest(
		data?.analysis_id ? 'content_manager.list' : null,
		{
			params: {
				fields: ['dataset_item_id'],
				filter: {
					analysis_id: { $eq: data?.analysis_id },
				},
				limit: 10,
				model: 'AnalysisDatasetItem',
			},
		},
	);
	const analysisDatasetItemData = analysisDatasetItemResponse?.data?.items;

	const { data: datasetItemResponse } = useRequest(
		analysisDatasetItemData?.length ? 'content_manager.list' : null,
		{
			params: {
				fields: [
					'content',
					'created_at',
					'dataset.id',
					'dataset.name',
					'prompts',
				],
				filter: {
					id: {
						$in: analysisDatasetItemData?.map((item) => item.dataset_item_id),
					},
				},
				limit: 10,
				model: 'DatasetItem',
			},
		},
	);
	const datasetItemData = datasetItemResponse?.data?.items;

	return (
		<>
			{datasetItemData?.map((item, index) => {
				return <DatasetItem item={item} key={index} />;
			})}
		</>
	);
};

DatasetAside.propTypes = {
	data: PropTypes.object,
};

Request.propTypes = {
	data: PropTypes.object,
};

export default DatasetAside;
